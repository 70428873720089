import React from 'react';

export const onRenderBody = ({ setHeadComponents }) => {
  setHeadComponents([
    <meta
      key="google-site-verification"
      name="google-site-verification"
      content="dY4FtEShEtmdPYZ139pYT3T7fCVQJGI-ImKlAH74P6I"
    />,
    <script
      key="gtag-library"
      async
      src={`https://www.googletagmanager.com/gtag/js?id=AW-11366952624/EC82CPmvnOoYELDdl6wq`}
    />,
    <script
      key="gtag-init"
      dangerouslySetInnerHTML={{
        __html: `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'AW-11366952624');
        `,
      }}
    />,
  ]);
};
