exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-index-js": () => import("./../../../src/pages/aboutus/index.js" /* webpackChunkName: "component---src-pages-aboutus-index-js" */),
  "component---src-pages-blog-blogdetails-js": () => import("./../../../src/pages/blog/blogdetails.js" /* webpackChunkName: "component---src-pages-blog-blogdetails-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-casestudy-casestudydetail-js": () => import("./../../../src/pages/casestudy/casestudydetail.js" /* webpackChunkName: "component---src-pages-casestudy-casestudydetail-js" */),
  "component---src-pages-casestudy-index-js": () => import("./../../../src/pages/casestudy/index.js" /* webpackChunkName: "component---src-pages-casestudy-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-counter-js": () => import("./../../../src/pages/Industries/counter.js" /* webpackChunkName: "component---src-pages-industries-counter-js" */),
  "component---src-pages-industries-ecommerce-js": () => import("./../../../src/pages/Industries/ecommerce.js" /* webpackChunkName: "component---src-pages-industries-ecommerce-js" */),
  "component---src-pages-industries-index-js": () => import("./../../../src/pages/Industries/index.js" /* webpackChunkName: "component---src-pages-industries-index-js" */),
  "component---src-pages-layout-js": () => import("./../../../src/pages/Layout.js" /* webpackChunkName: "component---src-pages-layout-js" */),
  "component---src-pages-ourapproach-index-js": () => import("./../../../src/pages/ourapproach/index.js" /* webpackChunkName: "component---src-pages-ourapproach-index-js" */),
  "component---src-pages-projectlist-index-js": () => import("./../../../src/pages/projectlist/index.js" /* webpackChunkName: "component---src-pages-projectlist-index-js" */),
  "component---src-pages-seo-packages-index-js": () => import("./../../../src/pages/seoPackages/index.js" /* webpackChunkName: "component---src-pages-seo-packages-index-js" */),
  "component---src-pages-services-service-js": () => import("./../../../src/pages/services/Service.js" /* webpackChunkName: "component---src-pages-services-service-js" */),
  "component---src-pages-services-servicedetail-js": () => import("./../../../src/pages/services/servicedetail.js" /* webpackChunkName: "component---src-pages-services-servicedetail-js" */),
  "component---src-pages-trendingtechologies-index-js": () => import("./../../../src/pages/trendingtechologies/index.js" /* webpackChunkName: "component---src-pages-trendingtechologies-index-js" */)
}

